// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-project-template-js": () => import("../src/templates/projectTemplate.js" /* webpackChunkName: "component---src-templates-project-template-js" */),
  "component---src-templates-blog-template-js": () => import("../src/templates/blogTemplate.js" /* webpackChunkName: "component---src-templates-blog-template-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-js": () => import("../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-projects-featured-js": () => import("../src/pages/projects/featured.js" /* webpackChunkName: "component---src-pages-projects-featured-js" */),
  "component---src-pages-projects-misc-js": () => import("../src/pages/projects/misc.js" /* webpackChunkName: "component---src-pages-projects-misc-js" */),
  "component---src-pages-projects-mobile-js": () => import("../src/pages/projects/mobile.js" /* webpackChunkName: "component---src-pages-projects-mobile-js" */),
  "component---src-pages-projects-web-js": () => import("../src/pages/projects/web.js" /* webpackChunkName: "component---src-pages-projects-web-js" */)
}

